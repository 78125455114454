<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <ASuccessFour :api="this.api"/>
         <v-skeleton-loader ref="skeleton" type="table" v-if="api.isLoading">

        </v-skeleton-loader>
        <v-row v-else>
            <v-col class="mx-auto" cols="12" sm="7">
                <v-btn
                color="primary"
                class="my-3"
                @click="()=>{
                    this.$router.go(-1);
                }">
                    <v-icon>
                        mdi-arrow-left-bold
                    </v-icon>
                </v-btn>
                <v-card class="pa-4">
                        <!-- Card title -->
                    <v-toolbar class="secondary ma-4">
                        <div>
                            <v-toolbar-title class="white--text text-h5">
                                <v-card-title>
                                    Cancel Subscription
                                </v-card-title>                                
                            </v-toolbar-title>
                        </div>
                    </v-toolbar>
                    <!-- View company previous subscription -->
                    <!-- <div class="my-7 mx-4">
                        <router-link  :to="{name:'CompaniesSubscription',params:{id:this.$router.history.current.params.id}}">
                            <v-btn color="primary">
                                View all previous subscription
                            </v-btn>
                        </router-link>

                    </div> -->
                    <div class="mx-4 mt-6 pt-2">
                        
                        <v-text-field label="Company" v-model="form.company" dense outlined disabled filled>
                        </v-text-field>

                        <v-autocomplete 
                        v-if="companySubscriptionOptions!=null" 
                        v-model="form.stripeSubscription"
                        return-object :items="companySubscriptionOptions" outlined dense  small-chips 
                        item-value="stripe_id" item-text="plan_name"
                         deletable-chips label="Plan Name">
                         <template v-slot:item="{item}" >
                            <p>
                                {{ item.plan_name }} ( RM {{ item.price }})
                            </p>
                         </template>
                        </v-autocomplete>
                        <!-- Plan name and Coupon -->
                    </div>
                    <!-- Checkbox if user want or not schedule the subscription -->
                    <!-- <div class="mx-4 mt-4">
                        <v-checkbox v-model="form.isWantScheduleSubscription" @click="resetScheduledSubscription" label="Do you want to schedule the subscription">

                        </v-checkbox>
                    </div> -->

                    <!-- <div class="mx-4 mt-4" v-if="form.isWantScheduleSubscription">
                        <v-text-field prepend-icon="mdi-calendar" dense outlined @click:prepend="redirectScheduleSubscription" @click="redirectScheduleSubscription" label="Scheduled Subscription" v-model="form.scheduledSubscription" >
                            
                        </v-text-field>

                        <v-dialog v-model="isScheduledSubscription" height="auto" width="auto">
                            <v-card class="pa-3">
                                <div class="my-1">
                                    <p class="d-flex justify-start mt-3">
                                        Scheduled Subscription
                                    </p>
                                    <div class="d-flex justify-start">
                                        <v-date-picker v-model="form.scheduledSubscription" :landscape="true" :reactive="true">
                                        </v-date-picker>
                                    </div>
                                </div>
                                <slot name="footer">
                                    <div class="d-flex justify-end my-3" color="white">
                                        <v-btn color="primary" plain @click="resetScheduledSubscription">
                                            Cancel
                                        </v-btn>
                                        <v-btn color="primary" @click="confirmScheduleSubscription">
                                            Confirm
                                        </v-btn>
                                    </div>
                                </slot>
                            </v-card>

                        </v-dialog>
                    </div> -->
                    <div>
                        <!-- Use default payment method -->
                    </div>
                    <div class="d-flex justify-end">
                        <v-btn color="primary" plain @click="redirectBack">
                            Cancel
                        </v-btn>
                        <v-btn color="primary" class="mx-3" @click="validateInput">
                            Confirm
                        </v-btn>
                        <!-- Activate button -->
                    </div>
                </v-card>
            </v-col>
        </v-row>
       
    </v-container>
</template>
<script>
import { mapState } from 'vuex'
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components:{
        AConfirmation,
        ASuccessFour,
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        isScheduledSubscription:false,
        isPending:false,
        form:{
            company:null,
            stripeSubscription:null,
        },
        companySubscriptionOptions:null,
        api:{
            isSuccesful:false,
            isLoading:false,
            isError:false,
            error:null,
            success:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
            if(resp.class=='getCompanyActiveSubscription'){
                this.form.company = resp.data[0].name;
                this.companySubscriptionOptions = resp.data;

            }
            if(resp.class=="cancelCompanySubscription"){
                if(resp.status==422){
                    this.api.error = "Subscription plan invalid";
                    this.api.isError = true;
                    return 1
                }
                if(resp.status==401){
                    this.api.error = "Unauthorize action";
                    this.api.isError = true;
                    return 1;
                }
                this.api.isSuccesful = true;
                this.api.success = "Succesfully cancel company subscription";
            }
            this.api.isError = false;
            this.api.isLoading = false;

        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
                // let availablePlanApi = this.fetchAvailablePlan();
                let companiesSubscriptionApi = this.fetchCompaniesSubscription();
                this.$api.fetch(companiesSubscriptionApi);

            },
            redirectBack(){
                this.$router.go(-1);
            },
        fetchCompaniesSubscription(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/subscription/active/company/'+this.$router.history.current.params.id+"?user_id="+this.$store.getters.getUserId;
            return tempApi;   
        },
        submit(){
            this.isPending = false;
            let createCancelSubscriptionApi = this.createCancelSubscription();
            this.$api.fetch(createCancelSubscriptionApi);
            // let createActivateSubscriptionApi = this.createActivateSubscription();
            // this.$api.fetch(createActivateSubscriptionApi);
        },
        createCancelSubscription(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription/cancel/user/"+this.$store.getters.getUserId;
            let formData = new FormData;
            formData.append('stripe_id',this.form.stripeSubscription.stripe_id);
            tempApi.params = formData;
            return tempApi;
        },
        // createActivateSubscription(){
        //     let tempApi = this.$_.clone(this.api);
        //     tempApi.method = "POST";
        //     tempApi.url = process.env.VUE_APP_SERVER_API+"/subscription/activate/company/"+this.$router.history.current.params.id;  
        //     let formData = new FormData;
        //     formData.append('plan_id',this.form.plan.plan_id);
        //     formData.append('user_id',this.$store.getters.getUserId);
        //     if(this.form.scheduledSubscription!=null)
        //     {
        //         formData.append('scheduleSubscribed','on');
        //         formData.append('date',this.form.scheduledSubscription);
        //     }
        //     formData.append('defaultPaymentMethod','on');
        //     if(this.form.coupon!=null){
        //         formData.append('stripeCoupon',this.form.coupon)
        //     }
        //     tempApi.params = formData;
        //     return tempApi;
        // },
        closeDetail(){
            this.$router.go(-1);
        },
        redirectScheduleSubscription(){
            this.isScheduledSubscription = true;
        },
        resetScheduledSubscription(){
            this.isScheduledSubscription = false;
            this.form.scheduledSubscription = null;
        },
        confirmScheduleSubscription(){
            this.isScheduledSubscription = false;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        validateInput(){
            this.isPending = true;
        },

    }
}
</script>